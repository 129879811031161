exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-krepselis-jsx": () => import("./../../../src/pages/krepselis.jsx" /* webpackChunkName: "component---src-pages-krepselis-jsx" */),
  "component---src-pages-paieska-jsx": () => import("./../../../src/pages/paieska.jsx" /* webpackChunkName: "component---src-pages-paieska-jsx" */),
  "component---src-pages-privatumo-politika-jsx": () => import("./../../../src/pages/privatumo-politika.jsx" /* webpackChunkName: "component---src-pages-privatumo-politika-jsx" */),
  "component---src-pages-produktai-index-jsx": () => import("./../../../src/pages/produktai/index.jsx" /* webpackChunkName: "component---src-pages-produktai-index-jsx" */),
  "component---src-pages-produktai-shopify-product-product-type-index-jsx": () => import("./../../../src/pages/produktai/{ShopifyProduct.productType}/index.jsx" /* webpackChunkName: "component---src-pages-produktai-shopify-product-product-type-index-jsx" */),
  "component---src-pages-produktai-shopify-product-product-type-shopify-product-handle-jsx": () => import("./../../../src/pages/produktai/{ShopifyProduct.productType}/{ShopifyProduct.handle}.jsx" /* webpackChunkName: "component---src-pages-produktai-shopify-product-product-type-shopify-product-handle-jsx" */),
  "component---src-pages-taisykles-jsx": () => import("./../../../src/pages/taisykles.jsx" /* webpackChunkName: "component---src-pages-taisykles-jsx" */)
}

